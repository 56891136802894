<template>
  <div id="mainContainer">
    <div id="menu">
      <cq-side-nav v-if="isSideNavVisible()"> </cq-side-nav>
    </div>

    <div id="appContent">
      <cq-header v-if="isHeaderVisible()"></cq-header>

      <div id="app">
        <router-view v-if="isResettingPassword" name="resetPassword"></router-view>
        <router-view v-if="!isAuthenticated && !isCompanySelected && !isResettingPassword" name="login"></router-view>
        <router-view v-if="isAuthenticated && !isCompanySelected && !isResettingPassword" name="company"></router-view>
        <router-view class="currentView" v-if="isAuthenticated && isCompanySelected && !isResettingPassword && timeZoneLoaded()">
        </router-view>
        <div class="main-slider" v-show="isDashboardLoading()">
          <h4>
            {{ $t("loading") }}
          </h4>
          <v-progress-linear color="var(--primary-color)" indeterminate></v-progress-linear>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./components/master/Header.vue"
import SideNav from "./components/master/SideNav.vue"
import { appConfig } from "@/config/globals"
import { mapState, mapActions, mapGetters } from "vuex"

export default {
  name: "app",
  data() {
    return {
      instanceBellNotification: null,
      navInstance: null,
      interval: null,
      theme: "tria-theme",
      availableIcons: {
        "toolmind-theme": "favicon.ico",
        "combiq-theme": "cq-favicon.ico",
        "acticon-theme": "cq-favicon.ico",
        "tria-theme": "cq-tria.ico",
        "akka-sense-theme": "cq-favicon.ico",
      },
      availableTitles: {
        "toolmind-theme": "Toolmind",
        "combiq-theme": "CombiQloud",
        "acticon-theme": "CombiQloud",
        "tria-theme": "Tria",
        "akka-sense-theme": "akka-sense",
      },
      availableNames: {
        "toolmind-theme": "Toolmind",
        "combiq-theme": "CombiQ",
        "acticon-theme": "CombiQ",
        "tria-theme": "Tria",
        "akka-sense-theme": "Akkatek",
      },
    }
  },
  created() {
    this.setTheme()
    this.tryAutoLogin()
    // If user is not authenticated redirect them to the login page.
    if (!this.isAuthenticated && window.location.pathname !== "/") {
      this.logout()
      clearInterval(this.interval)
    }

    this.images = document.getElementsByTagName("img")
    this.$i18n.locale = "en"
  },
  mounted() {
    this.instanceBellNotification = M.Dropdown.init(document.getElementById("bellNotification"), {
      coverTrigger: false,
      hover: true,
      constrainWidth: false,
    })
    const collapsibleElements = document.querySelector(".collapsible")
    M.Collapsible.init(collapsibleElements)
  },
  components: {
    cqHeader: Header,
    cqSideNav: SideNav,
  },
  computed: {
    ...mapState({
      expirationDate: (state) => state.auth.expirationDate,
    }),
    ...mapGetters([
      "isAuthenticated",
      "isCompanySelected",
      "isResettingPassword",
      "isExpirationDatePresent",
      "currentCompanyTimeZone",
    ]),
  },
  methods: {
    ...mapActions(["tryAutoLogin", "logout", "setCompanyAssignment", "refreshUserTokens"]),
    switchLocale(lang) {
      this.$i18n.locale = lang
    },
    compareExpirationDate() {
      if (new Date() >= this.expirationDate) this.refreshUserTokens()
    },
    getThemeFromVariables() {
      const site = window.location.hostname.split(".")[0] + window.location.hostname.split(".")[1]
      for (const key in process.env) {
        const values = process.env[key].split(",")
        if (values[0] === site) {
          return values[1]
        }
      }
    },
    isDashboardLoading() {
      if (!this.isAuthenticated) return false

      if (!this.isCompanySelected) return false

      return !this.timeZoneLoaded()
    },
    isSideNavVisible() {
      return (
        this.isAuthenticated &&
        this.isCompanySelected &&
        !this.isResettingPassword &&
        this.$router.history.current.name !== "company_selector"
      )
    },
    isHeaderVisible() {
      return this.isAuthenticated && this.isCompanySelected && this.$router.history.current.name !== "company_selector"
    },
    setFavIcon(theme) {
      const link = document.querySelector("link[rel*='icon']") || document.createElement("link")
      link.type = "image/x-icon"
      link.rel = "shortcut icon"
      link.href = "/" + this.availableIcons[theme]
      document.getElementsByTagName("head")[0].appendChild(link)
    },
    setImagesAlt() {
      const images = document.getElementsByTagName("img")
      for (let i = 0; i < images.length; i++) {
        images[i].setAttribute("alt", this.availableTitles[this.theme])
      }
    },
    setTheme() {
      const newTheme = this.getThemeFromVariables()
      if (newTheme !== undefined) this.theme = newTheme
      document.title = this.availableTitles[this.theme]
      appConfig.name = this.availableNames[this.theme]
      this.setFavIcon(this.theme)
      document.documentElement.className = this.theme
    },
    timeZoneLoaded() {
      this.$store.dispatch("setCompanyPreferences").then(() => {
        if (this.$store.getters.currentCompanyTimeZone) {
          this.$updateTimeZone()
        }
      })
      return this.$store.getters.currentCompanyTimeZone !== null
    },
    watchTokenExpiration() {
      this.interval = setInterval(this.compareExpirationDate, 60000)
    },
  },
  watch: {
    $route() {
      this.$store.commit("resetTabsConfig")
      window.scrollTo(0, 0)
    },
    expirationDate(date) {
      if (date != null) this.watchTokenExpiration()
      else clearInterval(this.interval)
    },
  },
  updated() {
    this.setImagesAlt()
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
}
</script>

<style>
@import "./assets/css/app.css";

html,
body {
  height: 100% !important;
  margin: 0 !important;
}

button {
  background-color: transparent !important;
}

/*Color Scheme*/
:root {
  --primary-main-background-color: #fff;
  --primary-light: #fff;
  --primary-dark: #000;
  --primary-color: #244a6b;
  --primary-light-color: #ebe7e1;
  --primary-dark-color: #e34d4d;
  --primary-warning-color: #e67300;
  --primary-cancel-button-color: #ffffff;
  --primary-button-text-color: #ffffff;
  --primary-cancel-button-text-color: #000000;
  --primary-text-color: #000000;
  --dashboard-cards-back-color: #e9eae8;
  --title-card-text-color: #ffffff;
  --card-icon-color: #ffffff;
  --copyright-text-color: #ffffff;
  --copyright-text: "Copyright CombiQ AB";
  --img-header-height: 20px;
  --img-header-path: url("assets/combiq/combiq-new-logo.png");
  --sidenav-text-color: #ffffff;
  --img-login-path: url("assets/combiq/combiq-logo-slogan-pos.png");
  --notification-icon-color: #ffffff;
  --notification-primary-color: #e34d4d;
  --img-background: url("assets/combiq/combiq-background.png");
  --control-primary-color: #e34d4d;
  --control-dark-color: #244a6b;
  --img-menu: url("assets/combiq/combiq-logo-primary-neg.png");
  --partner-name: "combiq";
}

:root.acticon-theme {
  --primary-main-background-color: #fff;
  --primary-light: #fff;
  --primary-dark: #000;
  --primary-color: #003e52;
  --primary-light-color: #c9cad4;
  --primary-dark-color: #003e52;
  --primary-warning-color: #e67300;
  --primary-cancel-button-color: #ffffff;
  --primary-button-text-color: #ffffff;
  --primary-cancel-button-text-color: #000000;
  --primary-text-color: #000000;
  --dashboard-cards-back-color: #c9cad4;
  --title-card-text-color: #ffffff;
  --card-icon-color: #ffffff;
  --copyright-text-color: #ffffff;
  --copyright-text: "Copyright CombiQ AB";
  --img-header-height: 30px;
  --img-header-path: url("assets/acticon/banner.png");
  --sidenav-text-color: #ffffff;
  --img-login-path: url("assets/acticon/login.png");
  --notification-icon-color: #000000;
  --notification-primary-color: #b8deef;
  --img-background: url("assets/acticon/portal-background.jpg");
  --control-primary-color: #406d82;
  --control-dark-color: #003e52;
  --img-menu: url("assets/acticon/acticon-logo-primary-neg.png");
  --partner-name: "acticon";
}

:root.tria-theme {
  --primary-main-background-color: #fff;
  --primary-light: #fff;
  --primary-dark: #000;
  --primary-color: #575d5e;
  --primary-light-color: #cccccc;
  --primary-dark-color: #575d5e;
  --primary-warning-color: #a5a5a5;
  --primary-cancel-button-color: #ffffff;
  --primary-button-text-color: #ffffff;
  --primary-cancel-button-text-color: #000000;
  --primary-text-color: #000000;
  --dashboard-cards-back-color: #c9cad4;
  --title-card-text-color: #ffffff;
  --card-icon-color: #ffffff;
  --copyright-text-color: #ffffff;
  --copyright-text: "TRIA S.p.A.";
  --img-header-height: 80px;
  --img-header-path: url("assets/tria/banner.png");
  --sidenav-text-color: #ffffff;
  --img-login-path: url("assets/tria/login.png");
  --notification-icon-color: #000000;
  --notification-primary-color: #b8deef;
  --img-background: url("assets/tria/portal-background.jpg");
  --control-primary-color: #575d5e;
  --control-dark-color: #575d5e;
  --img-menu: url("assets/tria/logo-square.png");
  --partner-name: "tria cloud";
}

:root.akka-sense-theme {
  --primary-main-background-color: #fff;
  --primary-light: #fff;
  --primary-dark: #000;
  --primary-color: #2b4e5d;
  --primary-light-color: #c9cad4;
  --primary-dark-color: #2b4e5d;
  --primary-warning-color: #e67300;
  --primary-cancel-button-color: #ffffff;
  --primary-button-text-color: #ffffff;
  --primary-cancel-button-text-color: #000000;
  --primary-text-color: #000000;
  --dashboard-cards-back-color: #c9cad4;
  --title-card-text-color: #ffffff;
  --card-icon-color: #ffd628;
  --copyright-text-color: #ffd628;
  --copyright-text: "Copyright CombiQ AB";
  --img-header-height: 30px;
  --img-header-path: url("assets/akkasense/banner-neg.png");
  --sidenav-text-color: #ffd628;
  --img-login-path: url("assets/akkasense/login.png");
  --notification-icon-color: #000000;
  --notification-primary-color: #b8deef;
  --img-background: url("assets/akkasense/akkasense-background.png");
  --control-primary-color: #2b4e5d;
  --control-dark-color: #2b4e5d;
  --img-menu: url("assets/akkasense/menu-company.png");
  --partner-name: "akka-sense";
}

.background-round {
  background-color: rgba(0, 0, 0, 0.18);
  padding: 15px;
  border-radius: 50%;
}

.notification-badge {
  border-radius: 50%;
  font-size: 0.6rem;
  margin: 0 -0.8em;
  position: relative;
  right: 5px;
  top: -30px;
}

small {
  font-family: "proxima-nova", Arial, Helvetica !important;
  font-size: 0.8rem;
}

.v-application {
  font-family: "proxima-nova", Arial, Helvetica !important;
}
.v-application--wrap {
  min-height: 0vh !important;
}

.datepicker-container {
  flex-direction: column !important;
  line-height: 0;
}

.datepicker-date-display {
  flex-basis: auto !important;
  flex-grow: 1 !important;
  padding: 1em 2em !important;
}

.datepicker-modal {
  max-height: unset !important;
  top: 3% !important;
  width: 320px !important;
}

.datepicker-table {
  font-size: 14px !important;
  margin: 0 20px !important;
  width: 280px !important;
}

.datepicker-controls .select-month input {
  width: 80px !important;
}

.buttons-bar {
  padding: 0 !important;
  text-align: right;
}

.main-slider {
  padding: 12px;
}

.max-width {
  width: 100%;
}

.modal-footer {
  height: unset !important;
  padding: 2em !important;
}

.modal:not(.datepicker-modal) {
  max-height: 80% !important;
}

.dropdown-content {
  max-height: 300px !important;
  overflow-y: auto !important;
}

.container {
  width: 100% !important;
  max-width: 100% !important;
}

.tabs .tab {
  text-transform: none !important;
}
.v-autocomplete:not(.v-input--is-disabled).v-select.v-text-field input {
  border: none;
}
.v-card__title {
  word-break: break-word !important;
}
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
.v-data-table__mobile-row__cell {
  padding-left: 20px;
  text-align: right;
}
.v-messages__message {
  color: red;
  padding-left: 5px;
}
.selector-buttons {
  box-shadow: none !important;
  text-align: right;
  -webkit-box-shadow: none !important;
}

#mainContainer {
  display: flex;
  min-height: 100%;
  max-width: 3440px;
  overflow: hidden;
  width: 100%;
}
#menu {
  background-color: var(--primary-color);
}
#appContent {
  max-width: 3440px;
  overflow: hidden;
  width: 100%;
}

.currentView {
  padding: 1.5em 3em 0 3em;
}

.menu-icon,
.menu-icon-mini {
  color: var(--primary-light) !important;
  padding-top: 1em;
}
.menu-icon {
  font-size: 40px !important;
}
.menu-icon-mini {
  font-size: 30px !important;
}

strong {
  font-weight: bolder !important;
}

.el-tiptap-popper__menu__item:first-of-type {
  display: none !important;
}

.no-capitals {
  text-transform: none !important;
}
</style>
